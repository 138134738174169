import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Indoor_Cameras/IN-8401_WQHD/Video_Streaming/DangerBox';
import PrimaryBox from 'components/Indoor_Cameras/IN-8401_WQHD/Video_Streaming/PrimaryBox';
import NavButtons from 'components/Indoor_Cameras/IN-8401_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import VideoFormats from 'components/Products/Usermanuals/YoutubeVideoStreamingFormats';
export const _frontmatter = {
  "title": "IN-8401 2k+ WQHD Manual Video Streaming",
  "path": "/Indoor_Cameras/IN-8401_WQHD/Video_Streaming/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-8401 2k+ WQHD is the first INSTAR camera with the new 1440p chipset.",
  "image": "./P_SearchThumb_IN-8401WQHD_Video_Streaming.webp",
  "social": "/images/Search/P_SearchThumb_IN-8401WQHD_Video_Streaming.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8401WQHD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-8401 2k+ WQHD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8401 HD is the first INSTAR camera with the new 1440p chipset.' image='/images/Search/P_SearchThumb_IN-8401WQHD_Video_Streaming.webp' twitter='/images/Search/P_SearchThumb_IN-8401WQHD_Video_Streaming.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8401_WQHD/Video_Streaming/' locationFR='/fr/Indoor_Cameras/IN-8401_WQHD/Video_Streaming/' crumbLabel="Streaming" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8401-2k-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8401-2k-wqhd-manual",
        "aria-label": "in 8401 2k wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8401 2k+ WQHD Manual`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoFormats mdxType="VideoFormats" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "rtsp-streaming",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#rtsp-streaming",
        "aria-label": "rtsp streaming permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RTSP Streaming`}</h3>
    <p><em parentName="p">{`e.g.`}</em>{` `}<Link to="/Software/Windows/VLC_Player/" mdxType="Link">{`VLC Player`}</Link></p>
    <p>{`To use your camera with third-party software, you may need to enter a specific URL (web address) where your camera is available. The program can then access the video image of your camera directly. Step-by-step instructions for the most important programs can be found `}<Link to="/Software/" mdxType="Link">{`here`}</Link>{`.`}</p>
    <h3 {...{
      "id": "access-in-the-internal-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#access-in-the-internal-network",
        "aria-label": "access in the internal network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access in the internal network`}</h3>
    <p>{`Please use the following URLs to use your camera with third-party software:`}</p>
    <ul>
      <li parentName="ul">{`RTSP Stream 1: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/livestream/11`}</code></li>
      <li parentName="ul">{`RTSP Stream 2: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/livestream/12`}</code></li>
      <li parentName="ul">{`RTSP Stream 3: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@192.168.x.x:/livestream/13`}</code></li>
    </ul>
    <p><em parentName="p">{`User and password are your camera login - e.g.`}</em>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rtsp://admin:instar@192.168.1.25:554/livestream/11`}</code></p>
    <p>{`Please add the `}<em>{`x`}</em>{` according to the IP of your camera (e.g. for the AVM Fritzbox the first x = 178 and the second x = is the EndIP your camera receives from your router). If you have changed the `}<Link to="/Web_User_Interface/1440p_Series/Network/IP_Configuration/" mdxType="Link">{`RTSP port`}</Link>{` (default = `}<em>{`554`}</em>{`), you have to add it to the IP address of your camera.`}</p>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#remote-access",
        "aria-label": "remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Remote Access`}</h3>
    <p>{`You can use your `}<Link to="/Web_User_Interface/1440p_Series/Network/Remote_Access/" mdxType="Link">{`DDNS address`}</Link>{` to remote access your camera:`}</p>
    <ul>
      <li parentName="ul">{`RTSP Stream 1: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`rtsp://user:password@xxxx.ddns-instar.de:/livestream/11`}</code></li>
    </ul>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "jpg--mjpeg-streaming",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#jpg--mjpeg-streaming",
        "aria-label": "jpg  mjpeg streaming permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`JPG & MJPEG Streaming`}</h2>
    <p>{`To use your camera with third-party software, you may need to enter a JPG image URL (web address). You can find step-by-step instructions for the most popular programs `}<a parentName="p" {...{
        "href": "/en/Software/"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "access-in-the-internal-network-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-in-the-internal-network-1",
        "aria-label": "access in the internal network 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access in the internal network`}</h2>
    <p>{`Please use the following path to use your camera with third-party software:`}</p>
    <h4 {...{
      "id": "video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#video",
        "aria-label": "video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Video`}</h4>
    <ul>
      <li parentName="ul">{`MJPEG Path Channel 11: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/livestream/11?action=play&media=mjpeg&user=Username&pwd=Password`}</code></li>
      <li parentName="ul">{`MJPEG Path Channel 12: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/livestream/12?action=play&media=mjpeg&user=Username&pwd=Password`}</code></li>
      <li parentName="ul">{`MJPEG Path Channel 13: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/livestream/13?action=play&media=mjpeg&user=Username&pwd=Password`}</code></li>
    </ul>
    <p><strong parentName="p">{`Or`}</strong></p>
    <ul>
      <li parentName="ul">{`MJPEG Path Channel 11: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/livestream/11?action=play&media=mjpeg`}</code></li>
      <li parentName="ul">{`MJPEG Path Channel 12: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/livestream/12?action=play&media=mjpeg`}</code></li>
      <li parentName="ul">{`MJPEG Path Channel 13: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/livestream/13?action=play&media=mjpeg`}</code></li>
    </ul>
    <p><strong parentName="p">{`Or for MP4:`}</strong></p>
    <ul>
      <li parentName="ul">{`Fragmented MP4 Path Channel 11: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://Benutzer:Kennwort@192.168.x.x/livestream/11?action=play&media=fmp4`}</code></li>
      <li parentName="ul">{`Fragmented MP4 Path Channel 12: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://Benutzer:Kennwort@192.168.x.x/livestream/12?action=play&media=fmp4`}</code></li>
      <li parentName="ul">{`Fragmented MP4 Path Channel 13: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://Benutzer:Kennwort@192.168.x.x/livestream/13?action=play&media=fmp4`}</code></li>
    </ul>
    <h4 {...{
      "id": "snapshot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#snapshot",
        "aria-label": "snapshot permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Snapshot`}</h4>
    <ul>
      <li parentName="ul">{`JPG Path Channel 11: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.x.x/snap.cgi?chn=11&user=Username&pwd=Password`}</code></li>
      <li parentName="ul">{`JPG Path Channel 12: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.x.x/snap.cgi?chn=12&user=Username&pwd=Password`}</code></li>
      <li parentName="ul">{`JPG Path Channel 13: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://192.168.x.x/snap.cgi?chn=13&user=Username&pwd=Password`}</code></li>
    </ul>
    <p><strong parentName="p">{`Or`}</strong></p>
    <ul>
      <li parentName="ul">{`JPG Path Channel 11: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/snap.cgi?chn=11`}</code></li>
      <li parentName="ul">{`JPG Path Channel 12: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/snap.cgi?chn=12`}</code></li>
      <li parentName="ul">{`JPG Path Channel 13: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`http://User:Password@192.168.x.x/snap.cgi?chn=13`}</code></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      